export var ProjectStatus;
(function (ProjectStatus) {
    ProjectStatus["active"] = "active";
    ProjectStatus["closed"] = "closed";
    ProjectStatus["pending"] = "pending";
})(ProjectStatus || (ProjectStatus = {}));
export var FilterStatus;
(function (FilterStatus) {
    FilterStatus["Active"] = "Active";
    FilterStatus["All"] = "All";
    FilterStatus["Closed"] = "Closed";
    FilterStatus["Pending"] = "Pending";
})(FilterStatus || (FilterStatus = {}));
